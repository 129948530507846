import { useNavigate } from 'react-router-dom';
import { default as svg } from './success.svg';
import styles from './Referred.module.css';
import { ReferralNextSteps } from '@packages/web-shared/components/referrals/ReferralNextSteps';
import { Button, classList } from '@percihealth/react';

export const ReferredPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <main className={classList('grid-outer main', styles.main)}>
        <img src={svg} className={styles.img} />
        <p className={styles.header}>Thank you for the referral</p>
        <Button className={styles.btn} onClick={() => navigate('/')}>
          New referral
        </Button>
        <a className={styles.homepage} href="https://www.percihealth.com">
          Or return to the homepage
        </a>
      </main>
      <ReferralNextSteps />
    </>
  );
};
