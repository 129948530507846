import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ReferralForm.module.scss';
import { useFormik, FormikProvider } from 'formik';
import {
  payments,
  CreateDeafaultReferral,
  ReferralCreateDefaultSchema,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikCheckbox,
  FormikDropdownSearch,
  FormikInputText,
  FormikRadioButton,
  FormLabel,
  FormikPhoneInput,
  isFormikValid,
  Spinner,
} from '@percihealth/react';
import { useFetchServices } from '../../../../hooks/useFetchServices';
import { useFetchProfessionals } from '../../../../hooks/useFetchProfessionals';
import { getFetchResponseErrorMessage } from '@packages/web-shared';

export default function ReferralForm() {
  const navigate = useNavigate();
  const [responseError, setResponseError] = useState<string | undefined>(
    undefined,
  );

  const {
    loading: servicesLoading,
    error: servicesLoadingError,
    services,
  } = useFetchServices();
  const {
    loading: professionalsLoading,
    error: professionalsLoadingError,
    professionals,
  } = useFetchProfessionals();

  const servicesSource = Object.keys(services)
    .map((key) => {
      return { id: key, name: services[key].name };
    })
    .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

  const referralHandleOnsubmit = async (values: any, actions: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/referrals`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, type: 'default' }),
        },
      );

      if (!response.ok) {
        const errorMsg = await getFetchResponseErrorMessage(response);
        console.error(errorMsg);
        setResponseError(errorMsg);
        return;
      }

      navigate('/referred');
    } catch (error) {
      console.error(error);
      setResponseError((error as Error).message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      patient: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      },
      payment: payments[0],
      referrer: {
        firstname: '',
        lastname: '',
        email: '',
      },
      referral: {
        serviceId: undefined as string | undefined,
        hasPreferredProfessional: true,
        preferredProfessionalId: '',
      },
      acceptForm: false,
    } as CreateDeafaultReferral,
    validationSchema: ReferralCreateDefaultSchema(
      Object.keys(services),
      professionals,
    ),
    onSubmit: referralHandleOnsubmit,
  });

  useEffect(() => {
    if (servicesSource.length > 0) {
      formik.setFieldValue('referral.serviceId', servicesSource[0].id);
    }
  }, [services]);

  // Reset preferredProfessionalId on a serviceId change
  useEffect(() => {
    formik.setFieldValue('referral.preferredProfessionalId', '');
  }, [formik.values.referral.serviceId]);

  const professionalsSource = Object.keys(professionals)
    .filter((key) =>
      professionals[key].services.includes(
        formik.values.referral.serviceId ?? '',
      ),
    )
    .map((key) => {
      return { value: key, name: professionals[key].fullname };
    });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <FormFieldset title="Patient's details">
          <FormikInputText
            name="patient.firstname"
            required
            className={styles.half}
            label="Patient first name"
            value={formik.values.patient.firstname}
          />
          <FormikInputText
            name="patient.lastname"
            required
            className={styles.half}
            label="Patient surname"
            value={formik.values.patient.lastname}
          />
          <FormikInputText
            name="patient.email"
            required
            className={styles.half}
            label="Patient email address"
            value={formik.values.patient.email}
            onBlur={(e) => {
              formik.setFieldValue(
                'patient.email',
                e.target.value?.toLowerCase(),
              );
              formik.setFieldTouched(
                'patient.email',
                true /* set touched=true */,
                false /* do not validate, validation is set onChange */,
              );
            }}
          />
          <div>
            <FormLabel required>Is the patient insured or self pay?</FormLabel>
            <div className={styles['insured-container']}>
              {payments.map((payment: any) => (
                <FormikRadioButton
                  key={payment}
                  name="payment"
                  id={`payment.${payment}`}
                  text={payment}
                  value={payment}
                  checked={payment === formik.values.payment}
                />
              ))}
            </div>
          </div>
          <FormikPhoneInput
            name="patient.phone"
            className={styles.half}
            label="Patient telephone number"
            value={formik.values.patient.phone}
          />
        </FormFieldset>
        <FormFieldset title="Referrer's details">
          <FormikInputText
            name="referrer.firstname"
            required
            className={styles.half}
            label="Referrer first name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.referrer.firstname}
          />
          <FormikInputText
            name="referrer.lastname"
            required
            className={styles.half}
            label="Referrer surname"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.referrer.lastname}
          />
          <FormikInputText
            name="referrer.email"
            required
            className={styles.half}
            label="Referrer email address"
            value={formik.values.referrer.email}
            onBlur={(e) => {
              formik.setFieldValue(
                'referrer.email',
                e.target.value?.toLowerCase(),
              );
              formik.setFieldTouched(
                'referrer.email',
                true /* set touched=true */,
                false /* do not validate, validation is set onChange */,
              );
            }}
          />
        </FormFieldset>
        <FormFieldset title="Details of referral">
          <div className="full-width">
            <FormLabel required>
              Service I believe they will benefit from
            </FormLabel>
            <div className={styles['service-container']}>
              {servicesLoading && <Spinner />}
              {servicesLoadingError && (
                <AlertError>{servicesLoadingError}</AlertError>
              )}
              {!servicesLoading &&
                !servicesLoadingError &&
                servicesSource.map((service) => (
                  <FormikRadioButton
                    name="referral.serviceId"
                    key={service.id}
                    id={`referral.service.${service.id}`}
                    text={service.name}
                    value={service.id}
                    className={styles.service}
                    checked={service.id == formik.values.referral.serviceId}
                  />
                ))}
            </div>
          </div>
          <div>
            <FormLabel required>
              Do you have a preferred healthcare professional you would like to
              refer them to?
            </FormLabel>
            <div className={styles['prefferred-container']}>
              <FormikRadioButton
                name="referral.hasPreferredProfessional"
                id="hasPreferredProfessional_yes"
                text="Yes"
                value={'true'}
                className={styles.preffered}
                checked={formik.values.referral.hasPreferredProfessional}
              />
              <FormikRadioButton
                name="referral.hasPreferredProfessional"
                id="hasPreferredProfessional_no"
                text="No"
                value={'false'}
                className={styles.preffered}
                checked={!formik.values.referral.hasPreferredProfessional}
              />
            </div>
          </div>
          <div>
            {formik.values.referral.hasPreferredProfessional && (
              <div>
                <FormLabel required htmlFor="referral.preferredProfessionalId">
                  Select the name of your preferred healthcare professional
                </FormLabel>
                {professionalsLoading && <Spinner />}
                {professionalsLoadingError && (
                  <AlertError>{professionalsLoadingError}</AlertError>
                )}
                {!professionalsLoading && !professionalsLoadingError && (
                  <FormikDropdownSearch
                    id="referral.preferredProfessionalId"
                    name="referral.preferredProfessionalId"
                    options={professionalsSource}
                    search
                    fuzzySearch
                    emptyMessage="Not found"
                    placeholder="Select a professional"
                  />
                )}
              </div>
            )}
          </div>
        </FormFieldset>
        <FormFieldset title="">
          <div className="full-width"></div>
          <FormikCheckbox
            name="acceptForm"
            required
            value={formik.values.acceptForm.toString()}
            text="I confirm I have consent from the patient to refer them to Perci Health"
          />
          <div>
            <Button
              type="submit"
              fullWidth
              disabled={!isFormikValid(formik)}
              submitting={formik.isSubmitting}
            >
              Submit
            </Button>
          </div>
          <div className="full-width">
            {responseError && <AlertError>{responseError}</AlertError>}
            <p>
              <b>Note:</b>{' '}
              <i>
                {' '}
                You can also refer a patient by emailing us your referral form
                to{' '}
                <a href="mailto:referrals@percihealth.com">
                  referrals@percihealth.com
                </a>
                . Please download a PDF referral form{' '}
                <a target="_blank" href="/docs/perci-referral-form-A4.pdf">
                  here
                </a>
                .
              </i>
            </p>
          </div>
        </FormFieldset>
      </Form>
    </FormikProvider>
  );
}
