import Referral from './components/pages/Referral';
import { Layout } from '@packages/web-shared/components/referrals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ReferredPage } from './components/pages/ReferredPage';
import { ScrollToTop } from '@percihealth/react';

export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <Layout>
            <ScrollToTop />
            <Outlet />
          </Layout>
        }
      >
        <Route path="/" element={<Referral />} />
        <Route path="referred" element={<ReferredPage />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}
