import { ReferralNextSteps } from '@packages/web-shared/components/referrals/ReferralNextSteps';
import { Lead } from '@percihealth/react';
import ReferralForm from './ReferralForm';

export default function Referral() {
  return (
    <>
      <main className="grid-outer main">
        <Lead title="Refer a patient">
          <p>
            If you are a healthcare professional and would like to refer one of
            your patients to our cancer professionals or services, please fill
            in the following form.
            <br />
            To make a referral, you will need consent from the patient, access
            to their key details, and a suggestion of the type of support you
            believe the patient will need.
          </p>
          <p>All details provided are encrypted and stored securely by us.</p>
        </Lead>
        <ReferralForm />
      </main>
      <ReferralNextSteps />
    </>
  );
}
