import { ProfessionalInfo } from '@packages/core-shared';
import { useEffect, useState } from 'react';

export const useFetchProfessionals = () => {
  const [loading, setLoading] = useState(false);
  const [professionals, setProfessionals] = useState<
    Record<string, ProfessionalInfo>
  >({});
  const [error, setError] = useState<string | undefined>(undefined);

  // Services
  useEffect(() => {
    const load = async () => {
      try {
        setError(undefined);
        setLoading(true);

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/professionals`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (!response.ok) {
          setError('Failed to fetch professionals');
        } else {
          const data = await response.json();
          setProfessionals(data);
        }
      } catch (error) {
        console.error(error);
        setError('Failed to fetch professionals');
      } finally {
        setLoading(false);
      }
    };
    load();
  }, []);

  return { loading, professionals, error };
};
